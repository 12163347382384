export const VIDEO_PROPS = {
  controls: true,
  autoPlay: true,
  loop: true,
  muted: true,
  playsInline: true,
};

export const IMAGE_PROPS = {};

export const VERTICAL_MARGINS = 120;
