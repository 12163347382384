import React from 'react';
import {Dropdown, DropdownOptionProps} from 'wix-ui-tpa/Dropdown';
import {IProductOptionSelectionItem, IProductOptionsItem} from '../../../types/productDef';
import {getDropdownOptionsFromSelections} from './OptionsDropdownUtils';
import st from './OptionsDropdown.st.css';
import s from './OptionsDropdown.scss';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {ProvidedGlobalProps, withGlobalProps} from '../../../providers/globalPropsProvider';

export interface OptionsDropdownProps extends IProvidedTranslationProps, ProvidedGlobalProps {
  dropdown: IProductOptionsItem;
  onSelect(selected: IProductOptionSelectionItem[]): void;
  selected?: IProductOptionSelectionItem;
}

export enum DataHook {
  Dropdown = 'options-dropdown',
}

@withGlobalProps
@withTranslations('globals.texts')
export class OptionsDropdown extends React.Component<OptionsDropdownProps> {
  private readonly onSelect = (nextSelected: DropdownOptionProps) => {
    const {
      onSelect,
      dropdown: {selections},
    } = this.props;
    const selection = selections.find(({id}) => id.toString() === nextSelected.id);
    onSelect([selection]);
  };

  public render() {
    const {
      t,
      selected,
      dropdown: {title, selections},
      globals: {product},
    } = this.props;
    const options = getDropdownOptionsFromSelections(selections);

    return (
      <div>
        <div data-hook="options-dropdown-title" className={s.title}>
          {title}
        </div>

        <Dropdown
          {...(st as any)('root')}
          key={`product-options-${product.id}`}
          options={options}
          onChange={this.onSelect}
          placeholder={t('SELECT_PLACE_HOLDER')}
          initialSelectedId={selected ? `${selected.id}` : ''}
          data-hook={DataHook.Dropdown}
          mobileNativeSelect
        />
      </div>
    );
  }
}
